import type { FilterItemOptions } from 'egenie-utils';
import { INNER_EMPTY_STATUS } from '../../../utils';

export function filterItems(): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      selectValue: '1',
      dateDictValue: 'recentThirtyDays',
      data: [
        {
          value: '1',
          label: '创建时间',
        },
      ],
    },
    {
      type: 'input',
      label: '快递单号',
      field: 'courierNo',
    },
    {
      type: 'select',
      label: '快递公司',
      field: 'courierName',
    },
    {
      type: 'select',
      label: '备注人',
      mode: 'multiple',
      field: 'noter',
    },
    {
      type: 'select',
      label: '创建人',
      mode: 'multiple',
      field: 'creator',
    },
    {
      type: 'select',
      field: 'unpackageTimeOut',
      label: '拆包超时',
      data: [
        {
          value: '1',
          label: '是',
        },
        {
          value: '0',
          label: '否',
        },
      ],
    },
    {
      type: 'select',
      label: '状态',
      mode: 'multiple',
      field: 'unpackingStatusList',
      isParamList: true,
    },
    {
      field: 'wmsAfterSaleOrderNo',
      type: 'radio',
      label: '关联售后单',
      data: [
        {
          value: INNER_EMPTY_STATUS.notEmpty.value,
          label: '关联售后单非空',
          showInput: true,
        },
        {
          value: INNER_EMPTY_STATUS.isEmpty.value,
          label: '关联售后单为空',
        },
      ],
    },
    {
      type: 'input',
      label: '备注',
      field: 'note',
    },
  ];
}
