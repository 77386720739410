import { Input, message, Modal } from 'antd';
import { renderModal } from 'egenie-common';
import type { PaginationData } from 'egenie-utils';
import { TimeStampFormatter, MainSubStructureModel, request } from 'egenie-utils';
import { Observer } from 'mobx-react';
import React from 'react';
import { RegistrationModal } from './registrationModal';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(context: Store) {
  const mainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        permissionId: '3023',
        text: '退件登记',
        icon: 'icon-d_deliver',
        handleClick: () => {
          renderModal(
            <RegistrationModal
              expressData={context.programme.filterItems.dict.courierName || []}
              onCancel={() => context.programme.gridModel.onQuery()}
            />
          );
        },
      },
      {
        permissionId: '6',
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = Array.from(context.programme.gridModel.gridModel.selectedIds)
            .join(',');
          const fileName = '退件登记';
          const exportType = 'cloud_wms_taking_express';
          const queryParam = context.programme.filterItems.params;
          const queryParamShow = context.programme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
      {
        permissionId: '104',
        text: '标记完成',
        icon: 'icon-print',
        handleClick: () => {
          const selectRows: MainItem[] = context.programme.gridModel.gridModel.selectRows;

          if (selectRows.length === 0) {
            const error = '请至少选择一条数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          if (selectRows.some((item) => item.unpackingStatus !== 0)) {
            const error = '仅可勾选未完成的数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            title: '确认标记完成吗?',
            onOk: () => request({
              url: '/api/cloud/wms/rest/taking_express/complete',
              method: 'POST',
              data: { wmsTakingExpressIdList: selectRows.map((item) => item.wmsTakingExpressId) },
            })
              .then(() => {
                message.success('标记完成成功');
                context.programme.gridModel.gridModel.resetAll();
                context.programme.gridModel.onRefresh();
              }),
          });
        },
      },
    ],
    pageId: '60031',
    grid: {
      columns: [
        {
          key: 'courierName',
          name: '快递公司',
          width: 200,
        },
        {
          key: 'courierNo',
          name: '快递单号',
          width: 200,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }) => {
            return <TimeStampFormatter value={row.createTime}/>;
          },
        },
        {
          key: 'note',
          name: '备注',
          width: 200,
          formatter: ({
            rowIdx,
            row,
          }: { rowIdx: number; row: MainItem; }) => {
            return (
              <Observer>
                {
                  () => (
                    <Input
                      onBlur={() => {
                        request({
                          url: '/api/cloud/wms/rest/taking_express/editNote ',
                          method: 'post',
                          data: {
                            id: row.wmsTakingExpressId,
                            note: mainSubStructureModel.gridModel.rows[rowIdx].note,
                          },
                        })
                          .then(() => {
                            message.success({
                              key: '备注修改成功',
                              content: '备注修改成功',
                            });
                          });
                      }}
                      onChange={(event) => mainSubStructureModel.gridModel.rows[rowIdx].note = event.target.value}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={mainSubStructureModel.gridModel.rows[rowIdx].note}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          key: 'noterName',
          name: '备注人',
          width: 150,
        },
        {
          key: 'createName',
          name: '创建人',
          width: 150,
        },
        {
          key: 'unpackageTimeOutDesc',
          name: '拆包超时',
          width: 80,
        },
        {
          key: 'unpackingStatusDesc',
          name: '状态',
          width: 80,
        },
        {
          key: 'wmsAfterSaleOrderNo',
          name: '关联售后单',
          width: 120,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsTakingExpressId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/returnRegistration/index',
    },

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/taking_express/obtain',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });
  return mainSubStructureModel;
}
