import { Input, message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { destroyAllModal } from 'egenie-common';
import type { ValueAndLabelData } from 'egenie-utils';
import { TimeStampFormatter, MainSubStructure, MainSubStructureModel, request, NormalProgramme, NormalProgrammeComponent } from 'egenie-utils';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import type { MainItem } from './types';

interface RegistrationModalProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
  expressData: ValueAndLabelData;
}

@observer
export class RegistrationModal extends React.Component<RegistrationModalProps> {
  constructor(props: RegistrationModalProps) {
    super(props);
    this.normalProgramme = new NormalProgramme({
      showButton: false,
      count: 3,
      filterItems: [
        {
          field: 'courierCompanyCode',
          type: 'select',
          label: '快递公司',
          data: props.expressData,
          required: true,
          onChangeCallback: (value: string) => {
            this.normalProgramme.filterItems.updateFilterItem([
              {
                field: 'courierNo',
                type: 'input',
                disabled: !value,
                value: '',
              },
            ]);
          },
        },
        {
          field: 'courierNo',
          type: 'input',
          label: '快递单号',
          required: true,
          disabled: true,
        },
      ],
      handleSearch: async() => {
        const params = this.normalProgramme.filterItems.params;

        try {
          await this.normalProgramme.filterItems.validator();
        } finally {
          this.normalProgramme.filterItems.getFilterItem('courierNo').reset();
        }

        const data = await request<BaseData<MainItem>>({
          url: '/api/cloud/wms/rest/taking_express/create ',
          method: 'post',
          data: params,
        });
        if (data.data && data.data.wmsTakingExpressId) {
          this.mainSubStructureModel.gridModel.rows.push(data.data);
        }
      },
    });
  }

  public normalProgramme: NormalProgramme;

  public mainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'courierName',
          name: '快递公司',
        },
        {
          key: 'courierNo',
          name: '快递单号',
        },

        {
          key: 'createTime',
          name: '创建时间',
          formatter: ({ row }) => {
            return <TimeStampFormatter value={row.createTime}/>;
          },
        },
        {
          key: 'note',
          name: '备注',
          formatter: ({
            rowIdx,
            row,
          }: { rowIdx: number; row: MainItem; }) => {
            return (
              <Observer>
                {
                  () => (
                    <Input
                      onBlur={() => {
                        request({
                          url: '/api/cloud/wms/rest/taking_express/editNote ',
                          method: 'post',
                          data: {
                            id: row.wmsTakingExpressId,
                            note: this.mainSubStructureModel.gridModel.rows[rowIdx].note,
                          },
                        })
                          .then(() => {
                            message.success({
                              key: '备注修改成功',
                              content: '备注修改成功',
                            });
                          });
                      }}
                      onChange={(event) => this.mainSubStructureModel.gridModel.rows[rowIdx].note = event.target.value}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].note}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          key: 'noterName',
          name: '备注人',
        },
        {
          key: 'createName',
          name: '创建人',
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsTakingExpressId',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: false,
      showPager: false,
      pageSize: 200,
    },

    api: { onQuery: () => Promise.resolve() },
    hiddenSubTable: true,
  });

  render() {
    return (
      <Modal
        centered
        maskClosable={false}
        okButtonProps={{ style: { display: 'none' }}}
        onCancel={() => {
          destroyAllModal();
          this.props.onCancel();
        }}
        title="退件登记"
        visible
        width={1200}
      >
        <NormalProgrammeComponent store={this.normalProgramme}/>
        <div style={{
          height: 400,
          marginTop: 8,
        }}
        >
          <MainSubStructure store={this.mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}

