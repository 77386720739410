import type { DictData } from 'egenie-common';
import { objToDict } from 'egenie-common';
import type { BaseData, FilterConfigData } from 'egenie-utils';
import { ExportModal, ExportStore, formatFilterConfigData, Programme, ProgrammeComponent, request } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { filterItems } from './filterItems';
import { mainSubStructureModel } from './mainSubStructureModel';

export class Store {
  constructor() {
    request<BaseData<Array<{ cpCode: string; courierName: string; }>>>({
      url: '/api/cloud/wms/rest/courier/dict',
      method: 'post',
    })
      .then((info) => {
        this.programme.filterItems.addDict({
          courierName: (info.data || []).map((item) => ({
            value: item.cpCode,
            label: item.courierName,
          })),
        });
      });

    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        itemList: 'user',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, {
          user: [
            'noter',
            'creator',
          ],
        });
        this.programme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });

    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/taking_express/unpackingType' })
      .then((info) => this.programme.filterItems.addDict({ unpackingStatusList: objToDict(info.data) }));
  }

  public mainSubStructureModel = mainSubStructureModel(this);

  public programme = new Programme({
    gridModel: this.mainSubStructureModel,
    filterItems: filterItems(),
    moduleName: 'egenieCloudWmsAfterSalesReturnRegistrationIndex',
  });

  public exportStore: ExportStore = new ExportStore({ parent: null });
}

const store = new Store();

@observer
export default class extends React.Component {
  render() {
    return (
      <>
        <ProgrammeComponent store={store.programme}/>
        <ExportModal store={store.exportStore}/>
      </>
    );
  }
}
